import "./Sidebar.scss";

import { faBars} from "@fortawesome/free-solid-svg-icons";
import abunLogo from "assets/images/branding/abun_blue_text_logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {pageURL} from "../../pages/routes";
// import { faBan, faLock, faLink } from "@fortawesome/free-solid-svg-icons";
import { LayoutDashboard, Users, ShieldCheck, UserX2, Ban, Lock, KeyRound, Link2, Tags, Settings, Download, ScrollText, MoreHorizontal } from 'lucide-react'
import { useState } from "react";

export default function Sidebar() {
	const [hamburgerActive, setHamburgerActive] = useState<boolean>(false);
	
	return (
		<aside className={`menu sidebar ${hamburgerActive ? "full-height" : ""} `}>
			{/* ============================================================================================= */}
			{/* ----------------------------------- Feature Request Modal ----------------------------------- */}
			{/* ============================================================================================= */}

			<div className={"mobile-extra"}>
				<img src={abunLogo} alt="Abun Logo" className={"mobile-logo"} width="128" height="auto" />
				<span className={"hamburger"} onClick={() => {
					setHamburgerActive(!hamburgerActive);
				}}>
				<FontAwesomeIcon icon={faBars}/>

				</span>
			</div>
			<section>
				<div className="sidebar-header">
					{/* ------------------------- LOGO ------------------------- */}
                        <img src={abunLogo}  className={`abun-logo `} alt={"abun logo"} width="128" height="auto"/>
                        <p>ADMIN</p>
				</div>

				{/* ------------------------- SIDEBAR ITEMS ------------------------- */}
			<div className="sidebar-items">
				<ul className="menu-list ">
				<li>
					<NavLink to={pageURL['dashboard']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<LayoutDashboard className="mr-2 h-4 w-4" />&nbsp;&nbsp;Dashboard
					</NavLink>
				</li>
				<li>
					<NavLink to={pageURL['allUsers']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<Users className="mr-2 h-4 w-4" />&nbsp;&nbsp;All Users
					</NavLink>
				</li>
				<li>
					<NavLink to={pageURL['admins']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<ShieldCheck className="mr-2 h-4 w-4" />&nbsp;&nbsp;Admins
					</NavLink>
				</li>
				<li>
					<NavLink to={"/ignored-competitors"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<UserX2 className="mr-2 h-4 w-4" />&nbsp;&nbsp;Ignored Competitors
					</NavLink>
				</li>
				<li>
					<NavLink to={"/blocked-domains"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<Ban className="mr-2 h-4 w-4" />&nbsp;&nbsp;Block Domains At Signup
					</NavLink>
				</li>
				<li>
					<NavLink to={"/blocked-websites"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<Lock className="mr-2 h-4 w-4" />&nbsp;&nbsp;Block Websites
					</NavLink>
				</li>
				<li>
					<NavLink to={"/blocked-website-keywords"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<KeyRound className="mr-2 h-4 w-4" />&nbsp;&nbsp;Block Keywords
					</NavLink>
				</li>
				<li>
					<NavLink to={"/backlinks"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<Link2 className="mr-2 h-4 w-4" />&nbsp;&nbsp;Add BackLinks
					</NavLink>
				</li>
				<li>
					<NavLink to={"/auto-coupons"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<Tags className="mr-2 h-4 w-4" />&nbsp;&nbsp;Add Coupons
					</NavLink>
				</li>
				<li>
					<NavLink to={pageURL['settings']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<Settings className="mr-2 h-4 w-4" />&nbsp;&nbsp;Settings
					</NavLink>
				</li>
				<li>
					<NavLink to={"/export/users"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<Download className="mr-2 h-4 w-4" />&nbsp;&nbsp;Export Users
					</NavLink>
				</li>
				<li>
					<NavLink to={"/logs"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<ScrollText className="mr-2 h-4 w-4" />&nbsp;&nbsp;Logs
					</NavLink>
				</li>
				<li>
					<NavLink to={"/others"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<MoreHorizontal className="mr-2 h-4 w-4" />&nbsp;&nbsp;Others
					</NavLink>
				</li>
			</ul>
			<hr className={"dropdown-divider"}/>
			{/* -------------------------------------------------------------------------------- */}
			<ul className="menu-list">
				<li>
					<NavLink to={pageURL['logout']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"sign-out"}/>&nbsp;&nbsp;Log Out
					</NavLink>
				</li>
			</ul>
				</div>
			</section>
		</aside>
	)
}
