import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import CheckBox from "components/CheckBox/CheckBox";
import Input from "components/Input/Input";
import PageLoading from "components/PageLoading/PageLoading";
import PageLoadingError from "components/PageLoadingError/PageLoadingError";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserData, retryFn, saveUserData } from "utils/api";
import Alert from "../../components/Alert/Alert";
import Modal from "../../components/Modal/Modal";
import { pageURL } from "../routes";
import Articles from "./Articles";
import KeywordProjects from "./KeywordProjects";
import "./ManageUser.scss";
import ManageUserWebsite from "./ManageUserWebsite";

interface UserData {
	username: string
	email: string
	email_verified: boolean
	country: string
	date_joined: string
	last_login: string
	user_tz: string
	titles_generated: number
	total_titles_generated: number
	articles_generated: number
	total_articles_generated: number
	send_notification_emails: boolean
	current_active_website: string
	all_connected_websites: UserWebsite[]
	website_logs: WebsiteLogs[]
	current_plan: string
	k8_tasks: Array<string>
	articles: ArticleTitle[]
	keyword_projects: KeywordProjects[]
	survey_details: Survey[]
}

interface UserWebsite {
	id: number
	name: string
	domain: string
	logo: string
	image_source: "no_image" | "unsplash" | "google" | "ai_image_generation"
}

interface Survey {
	question: string
	answer: string
}

interface WebsiteLogs {
	id: number
	website_domain: string
	date: string
	connection_type: null | 'connected' | 'disconnected'
	message: string
}

interface ArticleTitle {
	articleUID: string
	articleTitle: string
	keyword: string
	keywordTraffic: number | null
	internalLinks: number | null
	externalLinks: number | null
	images: number | null
	wordCount: number | null
	isProcessing: boolean
	isGenerated: boolean
	isPosted: boolean
	isFailed: boolean
	isArchived: boolean
	postLink: string
	isUserAdded: boolean
	postedTo: string
	feedback: string
}

interface KeywordProjects {
	projectName: string
	totalKeywords: number
	totalTrafficVolume: number
	dateCreated: string
	projectId: string
	locationIsoCode: string
}

export default function ManageUser() {
	// --------------------- REACT ROUTER PARAMS ---------------------
	let {userId} = useParams();

	// --------------------- STATES ---------------------
	const [userData, setUserData] = useState<UserData>({
		username: "",
		email: "",
		email_verified: false,
		country: "",
		date_joined: "",
		last_login: "",
		user_tz: "",
		titles_generated: 0,
		articles_generated: 0,
		total_titles_generated: 0,
		total_articles_generated: 0,
		send_notification_emails: false,
		current_active_website: "",
		all_connected_websites: [],
		website_logs: [],
		current_plan: "",
		k8_tasks: [],
		articles: [],
		keyword_projects: [],
		survey_details: [],
	});
	const [tasksModal, setTasksModal] = useState(false);
	const [taskSearch, setTaskSearch] = useState("");

	// --------------------- QUERY ---------------------
	const {
		isLoading,
		isError,
		error,
		data,
		refetch
	} = useQuery({
		queryKey: ['getUserData', userId],
		queryFn: () => getUserData(userId!),
		refetchOnWindowFocus: false,
		retry: retryFn
	})

	// --------------------- MUTATIONS ---------------------
	const saveUserDataMut = useMutation({
		mutationKey: ['saveUserData'],
		mutationFn: saveUserData,
		gcTime: 0,
		onSuccess: () => {
			successAlert.current?.show("All user details have been saved successfully!");
		},
		onError: (error) => {
			errorAlert.current?.show(`Failed to save user data: ${error}`);
		},
	})

	// --------------------- EFFECTS ---------------------
	useEffect(() => {
		if (data) {
			setUserData(data['data']);
		}
	}, [data]);

	// --------------------- REFS ---------------------
	const errorAlert = useRef<any>(null);
	const successAlert = useRef<any>(null);

	// --------------------- FUNCTIONS ---------------------
	function refetchUserData() {
		refetch().then();
	}

	function taskFilter() {
		return userData.k8_tasks.filter(value => {
			return value.includes(taskSearch);
		})
	}

	// ============================================================
	// --------------------- MAIN RENDER CODE ---------------------
	// ============================================================
	// if (!userId) return <PageLoadingError message={"User ID value is missing"}/>;

	if (isLoading) return <PageLoading/>;

	if (isError) return <PageLoadingError message={error as unknown as string}/>;

	return (
		<>
			{/* ******************* Tasks Modal ******************* */}
			<Modal active={tasksModal}
				headerText={""}
				closeable={true}
				hideModal={() => setTasksModal(false)}>
				<div className={"mt-5"}>
					<Input type={"text"}
									value={taskSearch}
									placeholder={"Search tasks..."}
									onChange={e => setTaskSearch(e.target.value)}/>
					<hr className={"dropdown-divider mt-4 mb-4"}/>
					<div className={"website-task-list"}>
						{taskFilter().length === 0 && <p className={"has-text-centered"}>No Tasks Found</p>}
						{taskFilter().map(task => (
							<Link to={pageURL['taskLogs']
								.replace(":userId", userId ? userId.toString() : "Not Found")
								.replace(":jobId", task)}
										className={"website-task-list--item"}
										key={task}>
								{task}
							</Link>
						))}
					</div>
				</div>
			</Modal>
			<div className={"manage-user-container"}>
				<Card>
					<div className={"is-flex is-flex-direction-row container-content"}>
						<div className="mr-6">
							<div className={"block"}>
								<h3 className={"is-size-3 mb-0 has-text-primary"}>{userData?.username}</h3>
								<p className={"is-size-6"}>{userData?.email}</p>
							</div>
							<div className={"block"}>
								<p className={"mb-2"}>
									<b>Date Joined:</b> {
									DateTime
										.fromISO(userData?.date_joined!)
										.setZone("Asia/Kolkata")
										.toLocaleString(DateTime.DATETIME_MED)
								}
								</p>
								<p>
									<b>Last Login:</b> {
									DateTime
										.fromISO(userData?.last_login!)
										.setZone("Asia/Kolkata")
										.toLocaleString(DateTime.DATETIME_MED)
								}
								</p>
							</div>
						</div>
						<div>
							<ul>
								{userData.survey_details.map((survey, index) => (
									<li className={"mb-4"} key={index}>
										<strong>{survey.question}</strong>
										<p>{survey.answer}</p>
									</li>
								))}
							</ul>
						</div>
					</div>
				</Card>
				<Card className={"mt-4"}>
					<div className={"is-flex is-flex-direction-row is-justify-content-space-between container-content"}>
						<h5 className={"is-size-5"}>User Account Details</h5>
						<div className={"is-flex is-flex-direction-row user-account-btns"}>
							{/* ******************** User Tasks ******************** */}
							{userData.k8_tasks.length > 0 && 
								<button type={"button"}
									className={"button is-primary ml-4 is-small"}
									style={{cursor: "pointer"}}
									onClick={() => setTasksModal(true)}>
									View Tasks
								</button>
							}
							{/* ******************** User Current Plan Button ******************** */}
							<button type={"button"}
								className={"button is-info ml-4 is-small"}
								style={{cursor: "default"}}>
								Current Plan: {userData.current_plan}
							</button>
							{/* ******************** Save User Details Button ******************** */}
							<Button color={"success"}
											className={"ml-4 is-small"}
											onClick={() => {
												if (userId) {
													saveUserDataMut.mutate({
														user_id: parseInt(userId),
														email_verified: userData.email_verified,
														email_notifications: userData.send_notification_emails,
														country: userData.country,
														user_tz: userData.user_tz,
														titles_generated: userData.titles_generated,
														total_titles_generated: userData.total_titles_generated,
														articles_generated: userData.articles_generated,
														total_articles_generated: userData.total_articles_generated,
													});
												} else {
													errorAlert.current?.show("Save failed: User ID value does not exists");
												}
											}}>
								<FontAwesomeIcon icon={"floppy-disk"}/>&nbsp;&nbsp;Save Changes
							</Button>
						</div>
					</div>
					<hr className={"dropdown-divider"}/>
					<div className={"user-details-form mt-4"}>
						<div className={"field"}>
							<CheckBox text={"Account Email Verified"}
												checked={userData.email_verified}
												onChange={(e) => setUserData({...userData, email_verified: e.target.checked})}/>
						</div>
						<div className={"field"}>
							<CheckBox text={"Email Notifications"}
												checked={userData.send_notification_emails}
												onChange={(e) => setUserData({...userData, send_notification_emails: e.target.checked})}/>
						</div>
						<div className={"user-stats-and-info"}>
							<div className={"info"}>
								<div className={"field"}>
									<label htmlFor={"country"} className={"label"}>Country:</label>
									<div className={"control"}>
										<Input type={"text"}
													id={"country"}
													value={userData.country}
													onChange={e => setUserData({...userData, country: e.target.value})}/>
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"usertz"} className={"label"}>Timezone:</label>
									<div className={"control"}>
										<Input type={"text"}
													id={"usertz"}
													value={userData.user_tz}
													onChange={e => setUserData({...userData, user_tz: e.target.value})}/>
										<a href={"https://www.zeitverschiebung.net/en/all-time-zones.html"}
											target={"_blank"}
											rel={"noreferrer"}
											className={"is-size-7"}>Timezone List for Reference</a>
									</div>
								</div>
							</div>
							<div className={"user-stats ml-6"}>
								<div className={"field"}>
									<label htmlFor={"titleUsage"} className={"label"}>Titles Generated This Month:</label>
									<div className={"control"}>
										<Input type={"number"}
													className={"stats-input-width"}
													id={"titleUsage"}
													value={userData.titles_generated}
													onChange={e => setUserData({...userData, titles_generated: parseInt(e.target.value)})}/>
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeTitleUsage"} className={"label"}>All Time Titles Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
													className={"stats-input-width"}
													id={"allTimeTitleUsage"}
													value={userData.total_titles_generated}
													onChange={e => setUserData({...userData, total_titles_generated: parseInt(e.target.value)})}/>
									</div>
								</div>
							</div>
							<div className={"user-stats ml-6"}>
								<div className={"field"}>
									<label htmlFor={"articleUsage"} className={"label"}>Articles Generated This Month:</label>
									<div className={"control"}>
										<Input type={"number"}
													className={"stats-input-width"}
													id={"articleUsage"}
													value={userData.articles_generated}
													onChange={e => setUserData({...userData, articles_generated: parseInt(e.target.value)})}/>
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>All Time Articles Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
													className={"stats-input-width"}
													id={"allTimeArticleUsage"}
													value={userData.total_articles_generated}
													onChange={e => setUserData({...userData, total_articles_generated: parseInt(e.target.value)})}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Card>
				{userData.all_connected_websites.length > 0 && <>
					<h3 className={"is-size-3 has-text-centered mt-6"}>User's Connected Websites</h3>
					{userData.all_connected_websites.map(website => (
						<ManageUserWebsite userId={parseInt(userId!)}
															websiteId={website.id}
															name={website.name}
															domain={website.domain}
															logo={website.logo}
															imageSource={website.image_source}
															className={"mt-4"}
															errorAlert={errorAlert}
															successAlert={successAlert}
															refetchUserData={refetchUserData}
															key={website.domain}/>
					))}
		</>}

				<div className={"block"}>
					<h4 className={"is-size-3 has-text-centered mt-6"}>
						Website Logs
					</h4>

					<Card>
						<div className={"log-list"}>
							{userData.website_logs.length === 0 && <p className={"has-text-centered"}>No Logs Available</p>}
							{userData.website_logs.map(log => {
								return (
									<div className={"log-list--item"} key={log.id}>
										<span className={"tag is-primary tag-width has-text-weight-bold is-capitalized"}>
											INFO
										</span>
										<p>
											{/*{log.created_on}*/}
											{DateTime
												.fromISO(log.date)
												.setZone("Asia/Kolkata")
												.toLocaleString(DateTime.DATETIME_MED)}
										</p>
										<p>{log.message}</p>
									</div>
								)
							})}
						</div>
					</Card>
				</div>

				{userData.articles.length > 0 && <>
					<Card>
						<h3 className={"is-size-3 has-text-centered"}>Articles</h3>
						<Articles articles={userData.articles}/>
					</Card>
				</>}

				{userData.keyword_projects.length > 0 && <>
					<Card className="mt-6">
						<h3 className={"is-size-3 has-text-centered"}>Keyword Projects</h3>
						<KeywordProjects keywordProjects={userData.keyword_projects} />
					</Card>
				</>}

				<Alert type={"danger"} ref={errorAlert}/>
				<Alert type={"success"} ref={successAlert}/>
			</div>
		</>
	);
}
